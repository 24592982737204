import WebClient from '../utils/web-client';
import AppTypes from '../action-types/app';

export const UpdateUserCookieSelection = (payload) => ({
    type: AppTypes.UPDATE_USER_COOKIE_SELECTION,
    payload,
});

export const ResetAPIStatus = () => ({
    type: AppTypes.API_RESET,
});

export const APIError = (payload = null) => ({
    type: AppTypes.API_ERROR,
    payload,
});

export const APISuccess = (payload = null) => ({
    type: AppTypes.API_SUCCESS,
    payload,
});

export const RequestDemoReset = () => ({
    type: AppTypes.REQUEST_DEMO_RESET,
});
const RequestDemoBegin = () => ({
    type: AppTypes.REQUEST_DEMO_BEGIN,
});
const RequestDemoSuccess = () => ({
    type: AppTypes.REQUEST_DEMO_SUCCESS,
});
const RequestDemoError = (errorMsg) => ({
    type: AppTypes.REQUEST_DEMO_ERROR,
    payload: { errorMsg },
});
export const RequestDemo = (data, onSuccess, onError) => async (dispatch) => {
    dispatch(RequestDemoBegin());

    try {
        await WebClient.post('/registrations', { data });
        dispatch(RequestDemoSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.status === 404
                || error.response.status === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(RequestDemoError(errorType));
        if (onError) onError(errorType);
    }
};

const GetContentBegin = () => ({
    type: AppTypes.GET_CONTENT_BEGIN,
});
const GetContentSuccess = (payload) => ({
    type: AppTypes.GET_CONTENT_SUCCESS,
    payload,
});
const GetContentError = () => ({
    type: AppTypes.GET_CONTENT_ERROR,
});

export const GetContent = () => async (dispatch) => {
    dispatch(GetContentBegin());

    try {
        const { data } = await WebClient.get('/content');
        // dispatch(APISuccess('Content successfully edited.'));
        dispatch(GetContentSuccess(data));
    } catch (error) {
        dispatch(GetContentError());
        // dispatch(APIError('Error editing content.'));
    }
};
