import { createSelector } from 'reselect';

const getAppState = (state) => state.app;

export const getUserCookieSelection = createSelector(
    getAppState,
    (appState) => appState.userCookieSelection,
);

export const getAPIStatus = createSelector(
    getAppState,
    (appState) => appState.apiStatus,
);

export const getRequestDemoStatus = createSelector(
    getAppState,
    (appState) => appState.requestDemo,
);

export const getPrivacyPolicy = createSelector(
    getAppState,
    (appState) => appState.privacy,
);
export const getTerms = createSelector(
    getAppState,
    (appState) => appState.terms,
);
export const getSecurity = createSelector(
    getAppState,
    (appState) => appState.security,
);
