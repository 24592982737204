const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    REQUEST_DEMO_BEGIN: true,
    REQUEST_DEMO_SUCCESS: true,
    REQUEST_DEMO_ERROR: true,
    REQUEST_DEMO_RESET: true,
    GET_CONTENT_BEGIN: true,
    GET_CONTENT_SUCCESS: true,
    GET_CONTENT_ERROR: true,
    API_ERROR: true,
    API_SUCCESS: true,
    API_RESET: true,
    UPDATE_USER_COOKIE_SELECTION: true,
});
