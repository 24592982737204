import React, { useCallback, Suspense, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';

import { UpdateUserCookieSelection } from '../../actions/app';
import { getUserCookieSelection } from '../../selectors/app';

import GoogleAnalytics from '../../components/google-analytics';

const Home = lazy(() => import('../../routes/home'));
const About = lazy(() => import('../../routes/about'));
const Demo = lazy(() => import('../../routes/demo'));
const Pricing = lazy(() => import('../../routes/pricing'));
const Header = lazy(() => import('../../components/header'));
const Footer = lazy(() => import('../../components/footer'));
const CookieBanner = lazy(() => import('../../components/cookie-banner'));
const Error404 = lazy(() => import('../../routes/error/404'));

const PublicContentPage = lazy(() => import('../../routes/content-page'));

const PublicLayout = () => {
    const dispatch = useDispatch();
    const userCookieSelection = useSelector(getUserCookieSelection);
    const setUserSelection = useCallback((choice) => {
        dispatch(UpdateUserCookieSelection(choice));
    });

    return (
        <Suspense fallback={<div />}>
            <Header />
            <main>
                <Routes>
                    <Route path="/error/404" element={<Error404 />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/demo" element={<Demo />} />
                    <Route path=":type" element={<PublicContentPage />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/*" element={<Navigate replace to="/error/404" />} />
                </Routes>
            </main>
            <Footer />
            {userCookieSelection && <GoogleAnalytics />}
            {userCookieSelection === null && <CookieBanner setUserSelection={setUserSelection} />}
        </Suspense>
    );
};

export default withRoot(PublicLayout, themer);
