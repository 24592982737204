import find from 'lodash/find';

import AppTypes from '../action-types/app';

const internals = {
    initial: () => ({
        userCookieSelection: null,
        apiStatus: {
            error: false,
            errorMsg: null,
            completed: false,
            successMsg: null,
        },
        requestDemo: {
            waiting: false,
            error: false,
            errorMsg: '',
            completed: false,
        },
        privacy: {},
        terms: {},
        security: {},
        getContent: {
            error: false,
            errorMsg: '',
            completed: false,
        },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AppTypes.UPDATE_USER_COOKIE_SELECTION:
        return {
            ...state,
            userCookieSelection: payload,
        };
    case AppTypes.API_RESET:
        return {
            ...state,
            apiStatus: {
                error: false,
                errorMsg: null,
                completed: false,
                successMsg: null,
            },
        };
    case AppTypes.API_ERROR:
        return {
            ...state,
            apiStatus: {
                error: true,
                errorMsg: payload,
                completed: true,
                successMsg: null,
            },
        };
    case AppTypes.API_SUCCESS:
        return {
            ...state,
            apiStatus: {
                error: true,
                errorMsg: payload,
                completed: true,
                successMsg: null,
            },
        };
    case AppTypes.REQUEST_DEMO_RESET:
        return {
            ...state,
            requestDemo: {
                waiting: false,
                completed: false,
                error: false,
                errorMsg: '',
            },
        };
    case AppTypes.REQUEST_DEMO_BEGIN:
        return {
            ...state,
            requestDemo: {
                waiting: true,
                completed: false,
                error: false,
                errorMsg: '',
            },
        };
    case AppTypes.REQUEST_DEMO_SUCCESS:
        return {
            ...state,
            requestDemo: {
                waiting: false,
                completed: true,
                error: false,
                errorMsg: '',
            },
        };
    case AppTypes.REQUEST_DEMO_ERROR:
        return {
            ...state,
            requestDemo: {
                waiting: false,
                completed: false,
                error: false,
                errorMsg: '',
            },
        };
    case AppTypes.SAVE_ADMIN_CONTENT_SUCCESS: {
        return {
            ...state,
            [payload.type]: payload.data,
        };
    }
    case AppTypes.GET_CONTENT_BEGIN:
        return {
            ...state,
            getContent: {
                completed: false,
                error: false,
                errorMsg: '',
            },
        };
    case AppTypes.GET_CONTENT_SUCCESS:
        return {
            ...state,
            getContent: {
                completed: true,
                error: false,
                errorMsg: '',
            },
            privacy: find(payload, { title: 'privacy' }).content,
            terms: find(payload, { title: 'terms' }).content,
            security: find(payload, { title: 'security' }).content,
        };
    case AppTypes.GET_CONTENT_ERROR:
        return {
            ...state,
            getContent: {
                completed: true,
                error: true,
                errorMsg: '',
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
