import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 665, // customn value
            laptop: 1024,
            desktop: 1200,
        },
    },
    // mui attributes
    typography: {
        color: '#5B5770',
        fontFamily: "'Mulish', sans-serif",
        h1: {
            color: '#201A2D',
            fontSize: '4rem',
            fontWeight: 700,
            letterSpacing: '.2px',
        },
        h2: {
            color: '#373737',
            fontSize: '3.2rem',
            fontWeight: 700,
            letterSpacing: '0',
            lineHeight: '5.1rem',
        },
        h3: {
            color: '#2C2E30',
            fontSize: '2.1rem',
            fontWeight: 700,
            letterSpacing: '0',
        },
        h4: {
            fontFamily: 'Inter',
            fontSize: '1.3rem',
            fontWeight: 400,
            letterSpacing: '.23px',
            color: '#5B5770',
        },
        h5: {
            color: '#201A2D',
            fontSize: '1.3rem',
            fontWeight: 400,
            letterSpacing: '.22px',
        },
        body1: {
            fontSize: '1.8rem',
            color: '#201A2D',
            fontWeight: 400,
            fontFamily: 'Inter',
            lineHeight: '2.7rem',
        },
        body2: {
            fontSize: '2.2rem',
            fontWeight: 400,
            color: '#5B5B5B',
            fontFamily: 'Inter',
            lineHeight: '4.0rem',
        },
        button: {
            fontSize: '1.6rem',
            fontWeight: 700,
            textTransform: 'none',
            fontFamily: "'Mulish', sans-serif",
        },
    },
    spacing: 5,
    palette: {
        text: {
            primary: Colors.darkBlack,
        },
        primary: {
            main: '#563BD4',
            hover: 'rgba(86, 59, 212, 0.1)',
            light: 'rgba(86, 59, 212, 0.85)',
            accent: 'rgba(86, 59, 212, 0.1)',
        },
        secondary: {
            main: Colors.darkBlack,
            light: 'rgba(86, 59, 212, 0.10)',
        },
        background: {
            default: Colors.lightBeige,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    '& a': {
                        textDecoration: 'none',
                    },
                },
            },
        },
        MuiSelect: {
            icon: {
                top: 'calc(50% - 6px)',
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    marginBottom: '-9px',
                },
                colorPrimary: {
                    color: 'inherit',
                    backgroundColor: 'transparent',
                    border: 0,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontFamily: "'Mulish', sans-serif",
                    color: '#201A2D',
                    fontSize: '1.5rem',
                    fontWeight: 500,
                },
                root: {
                    marginLeft: 0, // not sure why this is set to default at -11px, noting incase this adjustment causes oddities.
                    '&.Checkbox-label': {
                        '& $label': {
                            fontWeight: 400,
                        },
                        color: Colors.textGrey,
                    },

                    '&.Checkbox-label-checked': {
                        color: Colors.textGrey,
                    },

                    '&.Radio-label': {
                        paddingRight: 15,
                        border: '1px solid rgba(171,168,158,0.2)',
                        borderRadius: 6,

                        '&:hover': {
                            borderRadius: 6,
                            backgroundColor: Colors.lightBeige,
                        },
                    },

                    '&.Radio-label-checked': {
                        backgroundColor: Colors.lightBeige,
                        '& $label': {
                            color: Colors.textGrey,
                        },
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                // if RadioGroup has nooutlines param set to true
                root: {
                    '&[data-nooutlines=true]': {
                        '& .Radio-label': {
                            border: 'none',
                            '&:hover': {
                                border: 'none',
                            },
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "'Mulish', sans-serif",
                    maxWidth: '100%',
                    color: '#201A2D',
                    fontSize: '1.4rem',
                    fontWeight: 500,
                    '&.Mui-focused, & .Mui-error': {
                        color: '#201A2D !important',
                    },
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "'Mulish', sans-serif",
                    maxWidth: '100%',
                    color: '#201A2D',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    '&.Mui-focused, & .Mui-error': {
                        color: '#201A2D !important',
                    },
                },
                shrink: {
                    transform: 'translate(0, 1.5px) scale(1)',
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                contained: {},
                root: {
                    fontFamily: 'inherit',
                    letterSpacing: 'normal',
                    marginLeft: 0,
                    fontSize: '1.1rem',
                    fontStyle: 'italic',
                    '&.MuiFormHelperText-error': {},
                    "&[role='alert']": {
                        color: Colors.error,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    color: Colors.textGrey,
                },
                input: {
                    color: '#5B5770',
                    fontFamily: "'Mulish', sans-serif",
                    fontSize: '1.5rem',
                    fontWeight: 600,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.filledBg,
                    color: '#222222',
                    fontSize: '1.3rem',
                    width: '100%',
                    borderRadius: 3,
                    marginTop: 25,
                    border: '1px solid rgba(171,168,158,0.2)',
                    '&$focused': {},
                    '&:hover': {
                        backgroundColor: Colors.white,
                    },
                    '&$error, &$error$focused': {
                        borderColor: Colors.error,
                    },
                    '&$disabled': {
                        color: `rgb(${Colors.charcoalRGB}, 0.5)`,
                        borderColor: 'rgba(171,168,158,0.2)',
                        backgroundColor: '#f5f6f5',
                        '& svg': {
                            color: Colors.grey,
                        },
                    },
                },
                input: {
                    padding: 15,
                    '&::placeholder': {
                        color: `rbga(${Colors.darkBlackRGB}, 0.7)`,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    color: '#222222',
                    fontSize: '1.3rem',
                    width: '100%',
                    borderRadius: 3,
                    marginTop: 25,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid rgba(171,168,158,0.5) !important',
                    },
                    '&.MuiOutlinedInput-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {},
                    },
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {},
                    },
                    '&.MuiOutlinedInput-error, &.MuiOutlinedInput-error.MuiOutlinedInput-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.error,
                        },
                    },
                    '&.MuiOutlinedInput-disabled': {
                        color: '#0F2032',
                        backgroundColor: '#f5f6f5',
                        '& svg': {
                            color: Colors.gray,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#f5f6f5',
                        },
                    },
                },
                input: {
                    padding: '16px 15px',
                    '&::placeholder': {
                        color: `rbga(${Colors.darkBlackRGB}, 0.7)`,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    transform: 'scale(1.4)',
                    color: `rgba(${Colors.charcoalRGB}, 0.5)`,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontFamily: "'Mulish', sans-serif",
                    color: '#201A2D',
                    fontSize: '1.7rem',
                    fontWeight: 500,
                    '&:hover': {
                        // backgroundColor: Colors.lightBeige,
                    },

                    '&.Mui-checked': {},
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation22: { // select menus, popover navigation
                    borderRadius: 8,
                    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
                },
                elevation1: { // table bgs
                    borderRadius: 19,
                },
                elevation2: { // regular menus
                    borderRadius: 5,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                },
                elevation3: { // about page
                    height: 144,
                    width: 282,
                    borderRadius: 20,
                    boxShadow: '-5px 4px 37px 0 rgba(0,0,0,0.1)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    paddingLeft: 30,
                    paddingRight: 30,
                },
                elevation23: { // data tables
                    boxShadow: 'none',
                    borderRadius: 19,
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.selectBg,
                    color: Colors.darkBlack,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: '2.2rem',
                    letterSpacing: 0.5,

                    '&:hover': {
                        color: Colors.blue,
                        backgroundColor: Colors.selectHover,
                    },

                    '&[aria-selected=true]': {
                        color: Colors.darkBlack,
                        backgroundColor: Colors.selectHover,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },

                    '&[role=menu]': { // menu items

                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                textSecondary: {
                    color: 'rgba(32, 26, 45, 0.6)',
                    '&.active': {
                        color: 'rgba(32, 26, 45, 1)',
                    },
                },
                contained: {
                    minWidth: 'fit-content',
                    textTransform: 'none',
                    borderRadius: 5,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 24,

                    '&:active, &[data-clicked=true]': {},
                },
                containedSecondary: {
                    backgroundColor: 'white',
                    color: '#563BD4',
                    '&:hover': {
                        opacity: 0.8,
                        backgroundColor: 'white',
                        color: '#563BD4',
                    },
                    '&:active, &[data-clicked=true]': {},
                },
                root: {
                    '&.Mui-disabled, &[aria-disabled=true]': {
                        color: `rgba(${Colors.darkGrayRGB}, 0.3) !important`,
                        backgroundColor: `${Colors.disabledBtnBackground} !important`,
                        border: '0px !important',
                        opacity: 1,
                    },
                },
            },
        },

        MuiSvgIcon: {
            styleOverrides: {
                root: {},
            },
        },

        MuiPickersDay: {
            styleOverrides: {
                day: {
                    '&:hover, &:focus': {},
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 30,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: Colors.darkBlack,
                    fontSize: '1.4rem',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    height: 40,
                    fontSize: '1.4rem',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                },
                action: {
                    padding: 0,
                    paddingLeft: 16,
                },
                filledSuccess: {
                    color: Colors.white,
                    backgroundColor: Colors.textGrey,
                },
                filledError: {
                    color: Colors.white,
                    backgroundColor: Colors.textGrey,
                },
                filledInfo: {
                    color: Colors.white,
                    backgroundColor: Colors.textGrey,
                },
            },
        },
    },
});

export default theme;
